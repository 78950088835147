import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import item from '../../components/item/index.vue'
import draggable from 'vuedraggable'

export default {
  name: 'test-questions',
  mixin: [mixins, validationMixin],
  data () {
    return {
      valid: false,
      draggableList: [],
      showRemoveDialog: false,
      deletedItem: null,
      updateList: true
    }
  },
  components: {
    draggable,
    item
  },
  watch: {
    'list' () {
      this.draggableList = this.list
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'tests/currentItem',
      list: 'testsQuestions/list',
      isListLoading: 'testsQuestions/isListLoading',
      isLoading: 'testsQuestions/isLoading'
    })
  },
  created () {
    this.fetchQuestions({
      id: this.currentItem.id
    }).then(() => {
      if (this.list.length) {
        this.draggableList = this.list
      } else {
        this.addQuestion()
      }
    })

    this.$on('event-save-all-question', () =>{
		this.saveAll()
    });
  },
  methods: {
    ...mapActions({
      fetchQuestions: 'testsQuestions/GET_LIST',
      removeQuestion: 'testsQuestions/DELETE',
      update: 'testsQuestions/UPDATE',
      updateSort: 'testsQuestions/UPDATE_SORT'
    }),

    saveAllQuestions(){
      this.saveAll()
    },

    saveAll(){
		let elements = document.querySelectorAll('.jq-test-save');

		for (let elem of elements) {
			let e = document.createEvent('HTMLEvents');
			e.initEvent('click', false, true);
			elem.dispatchEvent(e)
		}
    },

    showRemovePopup (item) {
      this.deletedItem = item
      this.showRemoveDialog = true
    },
    removeListItem () {
      if (this.deletedItem.hasOwnProperty('updatedAt')) {
        this.removeQuestion({
          ...this.$route.params,
          testId: this.currentItem.id,
          id: this.deletedItem.id
        }).then(response => {
          const index = this.draggableList.findIndex(e => e.id === this.deletedItem.id)
          this.draggableList.splice(index, 1)
          this.showRemoveDialog = false
          this.draggableList = this.list

          this.updateList = false
          this.$nextTick(() => {
            this.updateList = true
          })
        }).catch(() => {})
      } else {
        const index = this.draggableList.findIndex(e => e.id === this.deletedItem.id)
        this.draggableList.splice(index, 1)
        this.showRemoveDialog = false
      }
    },
    addQuestion () {
      this.draggableList.push({
        id: new Date().getTime(),
        name: '',
        typeId: this.currentItem.typeId || 1,
        sort: this.draggableList.length + 1,
        answerVariants: { data: [] }
      })
    },
    updateItem (item) {
      const index = this.draggableList.findIndex(e => e.id === item.id)
      Vue.set(this.draggableList, index, item)
    },
    changeSortQuestion: debounce(function (e) {
      this.updateList = false
      this.$nextTick(() => {
        this.updateList = true
      })
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.draggableList.map(e => e.id)
        const formData = new FormData()
        formData.append('testId', this.currentItem.id)
        formData.append('questions', sortsList.join(','))
        formData.append('_method', 'patch')
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
          this.draggableList = this.list
        })
      }
    }, 1)
  }
}
