import { mapGetters, mapMutations } from 'vuex'

import general from '../general/index.vue'
import questions from '../content/index.vue'
import seo from '../seo/index.vue'

export default {
  name: 'test-dialog-view',
  data () {
    return {
      activeTab: 'general',
      dialog: true,
    }
  },
  components: {
    general,
    questions,
    seo
  },
  computed: {
    ...mapGetters({
      showDialogView: 'tests/showDialogView',
      isLoading: 'tests/isLoading',
      currentItem: 'tests/currentItem',
      editedItem: 'tests/editedItem',
	  updateSeoLoading: 'tests/updateSeoLoading',
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          disabled: false,
          id: 1
        },
        {
          name: 'Questions',
          slug: 'questions',
          disabled: !this.currentItem,
          id: 2
        },
        {
          name: 'Seo',
          slug: 'seo',
          disabled: !this.currentItem,
          id: 3
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setItem: 'tests/SET_ITEM',
      changeShowDialog: 'tests/CHANGE_SHOW_DIALOG',
      changeEditItem: 'tests/CHANGE_EDIT_ITEM'
    }),

	save(){
	  this.$refs['general'].$emit('event-save');
	},

    saveAllQuestions(){
		this.$refs['general'].$emit('event-save-all-question');
    }
  },
  destroyed () {
    this.setItem(null)
    this.changeEditItem(null)
  }
}
