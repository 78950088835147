import { mapGetters } from 'vuex'
import questionText from '../question-text/index.vue'
import questionOptions from '../question-option/index.vue'

export default {
  name: 'test-content',
  components: {
    questionText,
    questionOptions
  },
  computed: {
    ...mapGetters({
      currentItem: 'homeWork/currentItem'
    })
  }
}
