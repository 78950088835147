import validationMixin from '@/mixins/validation'
import answerTemplates from '../../components/answer-templates/index.vue'
import MainEditor from '@/components/editor/index.vue'
import { mapActions, mapGetters } from 'vuex'

const snakeCaseKeys = require('snakecase-keys')
const camelCaseKeys = require('camelcase-object-deep')
export default {
  name: 'form-item',
  mixin: [validationMixin],
  props: {
    index: {
      type: Number
    },
    item: {
      type: Object
    },
    length: {
      type: Number
    }
  },
  components: {
    answerTemplates,
    MainEditor
  },
  data () {
    return {
      payload: {
        name: '',
        sort: this.index,
        typeId: this.item ? this.item.typeId : 1,
        responseTime: ''
      }
    }
  },
  watch: {
    payload: {
      handler (item) {
        this.update(item)
      },
      deep: true
    }
  },
  created () {
    Object.assign(this.payload, this.item)
  },
  computed: {
    questionTypes () {
      return [
        {
          id: 3,
          typeId: 3,
          name: 'Paragraph',
          icon: 'mdi-text-subject',
          placeholder: 'The text of the question with long answer'
        },
        {
          id: 2,
          typeId: 2,
          name: 'Checkbox and own answer',
          icon: 'mdi-checkbox-marked',
          placeholder: 'Description'
        },
        {
          id: 1,
          typeId: 1,
          name: 'Checkbox',
          icon: 'mdi-checkbox-marked',
          placeholder: 'Description '
        }
      ]
    },
    ...mapGetters({
      currentItem: 'tests/currentItem',
      list: 'testsQuestions/list',
      isLoading: 'testsQuestions/isLoading'
    }),
    disabledBtn () {
      return !this.payload.name || !this.payload.responseTime
    }
  },
  methods: {
    ...mapActions({
      fetchQuestions: 'testsQuestions/GET_LIST',
      fetchQuestionsItem: 'testsQuestions/GET_LIST_ITEM',
      createQuestions: 'testsQuestions/CREATE',
      updateQuestion: 'testsQuestions/UPDATE'
    }),

    saveAllQuestions(){
        let elements = document.querySelectorAll('.jq-test-save');

        for (let elem of elements) {
            let e = document.createEvent('HTMLEvents');
            e.initEvent('click', false, true);
            elem.dispatchEvent(e)
        }
    },

    remove () {
      this.$emit('remove-item', this.payload)
    },
    add () {
      this.$emit('add-item', this.item)
    },
    update (item) {
      this.$emit('update-item', item)
    },
    createRequestPayload () {
      const toSnakeCaseData = snakeCaseKeys(this.payload)
      const formData = new FormData()
      for (const i in toSnakeCaseData) {
        if (i !== 'id' && i !== 'answers') {
          formData.append(i, toSnakeCaseData[i])
        }
      }
      toSnakeCaseData.answer_variants.data.forEach((e, i) => {
        formData.append(`answers[${i}][correct_answer]`, e.correct_answer ? '1' : '0')
        if (e.id < 15869244252) {
          formData.append(`answers[${i}][id]`, e.id)
        }
        formData.append(`answers[${i}][name]`, e.name)
      })
      formData.append('testId', this.currentItem.id)
      formData.append('name', this.payload.name)
      if (this.payload.hasOwnProperty('updatedAt')) {
        formData.append('id', this.payload.id)
        formData.append('_method', 'patch')
      }

      return formData
    },
    updateListQuestions () {
      this.fetchQuestions({
        ...this.$route.params,
        id: this.currentItem.id
      })
    },
    save () {
      if (this.payload.hasOwnProperty('updatedAt')) {
        this.updateQuestion(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      } else {
        this.createQuestions(this.createRequestPayload()).then(response => {
          this.payload = camelCaseKeys(response)
          this.$toasted.success(this.$t('success_created'))
        })
      }
    }
  }
}
