import Vue from 'vue'
import itemCheckbox from './item/index.vue'

export default {
  name: 'form-checkbox',
  props: {
    payload: {
      type: Object
    },
    items: {
      type: Array
    }
  },
  components: {
    itemCheckbox
  },
  data () {
    return {
      list: [],
      renderComponent: true,
    }
  },
  created () {
    this.list = this.items
    this.list.length || this.addItem()
  },
  methods: {
    removeItem (item) {
      if (!item.disabled) {
        const index = this.list.findIndex(e => e.id === item.id)
        this.list.splice(index, 1)
      } else {
        this.isHasAnother = false
      }

      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    updateItem (item) {
      const index = this.list.findIndex(e => e.id === item.id)
      Vue.set(this.list, index, item)
    },
    addItem () {
      const item = {
        id: new Date().getTime(),
        text: '',
        correct: false
      }
      this.list.push(item)
    }
  }
}
