<template>
  <v-row>
    <v-col class="pa-0">
      <v-textarea
          style="width: 100%;"
          class="pt-0 pb-0 question-textarea"
          v-model.trim="name"
          rows="1"
          dense
          auto-grow
          :readonly="disabled"
          @input="update"
          :placeholder="`Description ${index+1}`">
        <template v-slot:prepend>
          <v-checkbox
              flat
              @change="update"
              dense
              v-model="correctAnswer"
              class="question-checkbox ma-0">
          </v-checkbox>
        </template>
        <template v-slot:append-outer>
          <v-btn
              v-if="length > 1 || disabled"
              @click.prevent="remove"
              fab
              x-small
              color="red">
            <v-icon color="white" x-small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-checkbox-item',
  props: {
    index: {},
    length: {},
    placeholder: {
      type: String,
      default: ''
    },
    typeId: {},
    disabled: {
      default: false
    },
    isHasCheckbox: {
      default: false
    },
    item: {
      type: Object
    },
  },
  data () {
    return {
      name: '',
      correctAnswer: ''
    }
  },
  created () {
    this.name = this.item?.name || ''
    this.correctAnswer = this.item?.correctAnswer || false
  },
  methods: {
    remove () {
      this.$emit('remove-item', {
        ...this.item,
        disabled: this.disabled
      })
    },
    update () {
      const copyItem = this.item
      copyItem.name = this.name
      copyItem.correctAnswer = this.correctAnswer
      this.$emit('update-item', copyItem)
    }
  }
}
</script>
