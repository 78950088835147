import { mapActions, mapGetters, mapMutations } from 'vuex'

import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'tests-item-seo',
  computed: {
    ...mapGetters({
      currentItem: 'tests/currentItem',
      seo: 'tests/seo',
      seoLoading: 'tests/seoLoading',
      updateSeoLoading: 'tests/updateSeoLoading',
    })
  },
  components: {
    SeoForm
  },

  created(){
	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },

  methods: {
    ...mapActions({
      update: 'tests/UPDATE_SEO'
    }),
    ...mapMutations({
      setSeo: 'tests/SET_SEO'
    }),
    submit (formData) {
      formData.parentId = this.currentItem.id

	  this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
      })
    }
  },
  destroyed(){
    this.setSeo(null)
  }
}
