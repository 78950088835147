import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import validation from '@/mixins/validation'
import mixins from '@/mixins'

export default {
  name: 'filter-home-works',
  mixin: [mixins, validation],
  data () {
    return {
      filter: {
        countries: '',
        categories: '',
        keywords: '',
        status: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      filterStatus: 'tests/filterStatus',
      filterCategories: 'tests/filterCategories',
      filterCountries: 'tests/filterCountries',
      filterKeywords: 'tests/filterKeywords',

      setting: 'courses/setting',
      accessCountries: 'profile/accessCountries',
      categories: 'educationCategories/list',
      categoriesLoading: 'educationCategories/isLoading'
    })
  },
  created () {
    if (!this.accessCountries) {
      this.fetchCountries().then(() => {
        this.setFilters()
      })
    }
    if (this.categories.length <= 0) {
      this.fetchCategories().then(() => {
        this.setFilters()
      })
    }
    this.setFilters()
  },
  methods: {
    ...mapActions({
      fetchList: 'tests/GET_LIST',
      fetchCountries: 'setting/GET_COUNTRIES',
      fetchCategories: 'educationCategories/GET_LIST'
    }),
    ...mapMutations({
      changeFilter: 'tests/CHANGE_LIST_FILTER'
    }),
    setFilters () {
      this.filter.status = this.filterStatus
      this.filter.categories = this.filterCategories
      this.filter.countries = this.filterCountries
      this.filter.keywords = this.filterKeywords
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 500),

    changeFilterLocal (val, type) {
      this.changeFilter({
        type: type,
        value: val
      })
      this.sendRequest()
    }
  }
}
