import { mapActions, mapGetters, mapMutations } from 'vuex'

import viewDialog from '../../pages/dialog-view/index.vue'
import mainFilter from '../../components/filter/index.vue'
import CountryFlag from 'vue-country-flag'

import mixins from '@/mixins'
export default {
  name: 'tests',
  mixin: [mixins],
  data () {
    return {
      page: 1
    }
  },
  components: {
    viewDialog,
    mainFilter,
    CountryFlag
  },
  computed: {
    ...mapGetters({
      list: 'tests/list',
      limit: 'tests/limit',
      skip: 'tests/skip',
      isListLoading: 'tests/isListLoading',
      listLength: 'tests/listLength',
      showDialogView: 'tests/showDialogView',
      setting: 'courses/setting'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Picture',
          value: 'picture'
        },
        {
          text: 'Category',
          value: 'category',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Country',
          value: 'country',
          sort: (a, b) => {
            const nameA = a.data.slug.toLowerCase()
            const nameB = b.data.slug.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Status',
          value: 'status',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  mounted () {
    if (!this.list.length) {
      this.fetchList()
    } else {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'tests/GET_LIST'
    }),
    ...mapMutations({
      setList: 'tests/SET_LIST',
      changeSkip: 'tests/CHANGE_SKIP',
      changeListLength: 'tests/SET_LIST_LENGTH',
      changeShowDialog: 'tests/CHANGE_SHOW_DIALOG',
      changeEditItem: 'tests/CHANGE_EDIT_ITEM'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    closeDialogView () {
      setTimeout(() => {
        this.changeShowDialog(false)
      }, 500)
    },
    changeDialogView (item) {
      this.changeEditItem(item)
      this.changeShowDialog(true)
    }
  },
  beforeDestroy () {
    this.setList([])
    this.changeSkip(0)
    this.changeListLength(0)
  }
}
