import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import HWContent from '../../components/content/index.vue'
import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'

import mixins from '@/mixins'
import validation from '@/mixins/validation';

import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'tests-general',
  mixin: [mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        id: '',
        countryId: '',
        statusId: '',
        description: '',
        previewDescription: '',
        categoryId: '',
        picture: '',
        locale: '',
        name: '',
        slug: '',
        active: false,
        minimumNumberOfCorrectAnswers: '',
        points: '',
        sort: '',
        useCustomRules: false,
        rules: '',
        authors: []
      }
    }
  },
  components: {
    HWContent,
    MainEditor,
    ImageFile,
    SlugNameInput,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      accessCountries: 'profile/accessCountries',
      isLoading: 'tests/isLoading',
      currentItem: 'tests/currentItem',
      editedItem: 'tests/editedItem',
      setting: 'courses/setting',
      locales: 'profile/locales',
      authors: 'user/speakers',
      isLoadingAuthors: 'user/speakersLoading',
      updateImageLoading: 'tests/updateImageLoading',
      categories: 'educationCategories/list',
      categoriesLoading: 'educationCategories/isLoading'
    })
  },
  created () {
    if (this.editedItem) {
      this.fetchItem({
        id: this.editedItem.id
      }).then(() => {
        this.setData()
        this.fetchAuthors({
          isAuthors: true,
        }).then(() => {
          this.setData()
        })
      })
    } else {
      this.fetchAuthors({
        isAuthors: true,
      })
    }
    if (this.categories.length <= 0) this.fetchCategories()

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapMutations({
      changeShowDialog: 'tests/CHANGE_SHOW_DIALOG',
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),
    ...mapActions({
      fetchItem: 'tests/GET_LIST_ITEM',
      update: 'tests/UPDATE',
      create: 'tests/CREATE',
      fetchAuthors: 'user/GET_SPEAKERS',
      updateImage: 'tests/UPDATE_IMAGE',
      fetchCategories: 'educationCategories/GET_LIST'
    }),

    onReloadUserPosition(){
        this.fetchAuthors({
            isAuthors: true,
        })
    },


    updateImageLocal (params) {
      params.append('test_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    // authors
    changeInputAuthors: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchAuthors({
          isAuthors: true,
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.authors = e.map(e => e.id)
      this.search = e
    },
    // authors end //
    setData () {
      const transformObj = {}
      const item = this.currentItem
      for (const i in item) {
        transformObj[camelCase(i)] = item[i]
      }
      Object.assign(this.payload, transformObj)
      this.payload.authors = this.currentItem.authors.data.map(e => e.userPositionId)
      this.setCountryIds([this.currentItem.country.data.id])
      this.search = this.payload.authors
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}

      for (const i in data) {
        formData[snakeCase(i)] = data[i]
      }
      if (this.editedItem) {
        formData.id = this.currentItem.id
        formData._method = 'patch'
      }

      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'));
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'));
		  })
        }
      }
    }
  }
}
